import { createSlice } from "@reduxjs/toolkit";

export const standSlice = createSlice({
  name: "Stand",
  initialState: {
    premio: [],
  },
  reducers: {
    setPremio:(state, action) => {
      state.premio = action.payload;
    },
  },
});

export const { setPremio } = standSlice.actions;
