import { createSlice } from "@reduxjs/toolkit";

export const questionSlice = createSlice({
  name: "User",
  initialState: {
    question: [],
  },
  reducers: {
    setQuestion:(state, action) => {
        state.question = action.payload;
    },
  },
});

export const { setQuestion } = questionSlice.actions;
