//Redux
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from '@reduxjs/toolkit'

// Slices
import { authSlice } from './slices/authSlice'
import { questionSlice } from './slices/questionSlice'
import { standSlice } from './slices/standSlice'
import { userSlice } from './slices/userSlice';

const reducers = combineReducers({
  auth: authSlice.reducer,
  user: userSlice.reducer,
  question: questionSlice.reducer,
  stand: standSlice.reducer,
})

const rootPersistConfig = {
  key:'root',
  storage,
}

const persistedReducer = persistReducer(rootPersistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});