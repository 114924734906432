import React, { useEffect, useState } from "react";
import { SpinnerLoading } from "../components/SpinnerLoading";

const Ranking = () => {
  const [ranking, setRanking] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRanking = async () => {
        const rankingRequest = await fetch("https://api.congresoendo.com/api/v1/users/ranking");
        const ranking = await rankingRequest.json();

        setRanking(ranking);
        setLoading(false);
    }
    fetchRanking();
  }, []);

  return loading ? <SpinnerLoading /> : (
    <div className="min-h-vh bg-repeat bg-endo p-4 flex flex-col items-center">
      <div className="text-center">
        <p className="text-6xl font-bold my-24">Ranking de EndoPoints</p>
      </div>
      <div>
        {
            ranking.map((user, index) => {
                return (
                    <div className="text-xl lg:text-5xl mb-8">
                        {index + 1} - {user.nombreCompleto} - <strong>{user.puntos}EP</strong>
                    </div>
                );
            })
        }
      </div>
    </div>
  );
};

export default Ranking;
