import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SpinnerLoading } from "../components/SpinnerLoading";
import Ranking from "../pages/Ranking";
import { PrivateRoutes } from "./PrivateRoute";

const Login = lazy(() => import("../pages/Login"));
const Home = lazy(() => import("../pages/Home"));
const Scanner = lazy(() => import("../pages/Scanner"));
const Questions = lazy(() => import("../pages/Questions"));
const Buy = lazy(() => import("../pages/Buy"));
const AppSelector = lazy(() => import("../pages/AppSelector"));
const SpeakerQuestions = lazy(() => import("../pages/SpeakerQuestions"));
const ModeratorQuestions = lazy(() => import("../pages/ModeratorQuestions"));
const ClinicalCases = lazy(() => import("../pages/ClinicalCases"));
const EndoTrivia = lazy(() => import("../pages/EndoTrivia"));

export const App = () => {
  return (
    <Suspense fallback={<SpinnerLoading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppSelector />} />
          <Route path="/clinical-cases" element={<ClinicalCases />} />
          <Route path="/endotrivia" element={<EndoTrivia />} />
          <Route path="/moderador" element={<ModeratorQuestions />} />
          <Route path="/speaker-questions" element={<SpeakerQuestions />} />
          <Route path="/endopoints-login" element={<Login />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route
            path="home"
            element={
              <PrivateRoutes>
                <Home />
              </PrivateRoutes>
            }
          />
          <Route
            path="scanner"
            element={
              <PrivateRoutes>
                <Scanner />
              </PrivateRoutes>
            }
          />
          <Route
            path="question/:id"
            element={
              <PrivateRoutes>
                <Questions />
              </PrivateRoutes>
            }
          />
          <Route
            path="buy/:buyId"
            element={
              <PrivateRoutes>
                <Buy />
              </PrivateRoutes>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
