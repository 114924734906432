import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "User",
  initialState: {
    userInfo: [],
    premios: [],
  },
  reducers: {
    setUserInfo:(state, action) => {
        state.userInfo = action.payload;
    },
    setPremios:(state, action) => {
        state.premios = action.payload;
    },
  },
});

export const { setUserInfo, setPremios } = userSlice.actions;
